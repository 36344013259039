<script lang="ts" setup>
import { defineComponent } from 'vue'

defineComponent({
  name: 'HeaderContent'
})

defineProps({
  type: {
    type: String,
    default: 'primary',
    validator: (value: string) => {
      return ['primary', 'secondary', 'both'].includes(value)
    }
  },
  showShadow: {
    type: Boolean,
    default: false
  },
  direction: {
    type: String,
    default: 'column-reverse',
    validator: (value: string) => {
      return ['column-reverse', 'row'].includes(value)
    }
  }
})
</script>

<template>
  <div
    data-test="header-content"
    class="header-content"
    :class="[showShadow ? 'header-content__shadow' : '']"
  >
    <div
      v-if="type == 'secondary' || type == 'both'"
      :class="[
        direction == 'column-reverse'
          ? 'header-content__primary header-content__flex-column'
          : 'header-content__primary header-content__flex-row',
        type == 'secondary' || type == 'both'
          ? 'header-content__secondary-spacing'
          : ''
      ]"
    >
      <div class="header-content__secondary-left header-content__flex-row">
        <slot name="secondary-left" />
      </div>
      <div class="header-content__secondary-right header-content__flex-row">
        <slot name="secondary-right" />
      </div>
    </div>
    <div
      v-if="type == 'primary' || type == 'both'"
      :class="[
        direction == 'column-reverse'
          ? 'header-content__primary header-content__flex-column'
          : 'header-content__primary header-content__flex-row',
        type == 'primary' ? 'header-content__primary-spacing' : ''
      ]"
    >
      <div class="header-content__primary-left header-content__flex-row">
        <slot name="primary-left" />
      </div>
      <div class="header-content__primary-right header-content__flex-row">
        <slot name="primary-right" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@base/styles/v1.0/scss/foundations/breakpoint';

.header-content {
  color: var(--color--neutral-a1);

  &__shadow {
    box-shadow: var(--box-shadow--b1);
  }

  &__secondary {
    margin-top: var(--spacing--7);
    justify-content: space-between;

    @media (min-width: breakpoint.$breakpoint--tablet) {
      margin-bottom: var(--spacing--7);
    }
  }

  &__secondary-spacing {
    @media (min-width: breakpoint.$breakpoint--tablet) {
      margin-bottom: var(--spacing--8);
    }
  }

  &__primary {
    justify-content: space-between;
    margin-bottom: var(--spacing--5);
  }

  &__primary-spacing {
    margin-top: var(--spacing--5);
  }

  &__primary-right {
    &:deep(.us-button) {
      margin-left: var(--spacing--5);
    }
  }

  &__secondary-left {
    margin-top: var(--spacing--8);

    @media (min-width: breakpoint.$breakpoint--tablet) {
      margin-top: 0;
    }
  }

  &__flex-column {
    align-items: flex-start;
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: breakpoint.$breakpoint--tablet) {
      align-items: center;
      flex-direction: row;
    }
  }

  &__flex-row {
    align-items: flex-start;
    display: flex;
    flex-direction: row;

    @media (min-width: breakpoint.$breakpoint--tablet) {
      align-items: center;
    }
  }
}
</style>
